import { RouteFormatter } from '../routes';

export const MOBILE_SIZE = 768;
export const DEFAULT_USER_LOCATION = { lat: 47.6062095, lng: -122.3320708 }; //Seattle // 47.617104,-122.318845
export const SPOTS_RADIUS = 50; // miles
export const NUMER_AFTER_POINT = 2;
export const ASPECT_RATIO = 1.28;
export const DEFAULT_MAP_ZOOM = 9;
export const END_TIME = 23.5;
export const IMAGE_BORDER_COLOR = 'd8d8d8';
export const SUBCRIBE_MODAL_TIME = 30000;
export const PAGE_LIMIT = 30;

export const GEOLOCATION_ERRORS = {
    PERMISSION_DENIED: {
        CODE: 1,
        MESSAGE: 'Sniffspot does not have permission to use your location.',
    },
};

export const ROUTE_TITLE_LINK = {
    '/about': ['About us', RouteFormatter.home()],
    '/cancel-reservation/': ['Cancel Reservation', null],
    '/customer-reviews': ['Customer Reviews', RouteFormatter.home()],
    '/booking-confirmation/': ['Booking Confirmation', RouteFormatter.reservationList()],
    '/favorites': ['My favorites', RouteFormatter.home()],
    '/5-stars-experience': ['5 Stars Experience', RouteFormatter.home()],
    '/dog-names': ['Dog names', null],
    '/guest_messages/': ['', null],
    '/host_messages/': ['', null],
    '/guarantee': ['Damage Protection', RouteFormatter.home()],
    '/memberships': ['Your memberships', null],
    '/subscriptions': ['Your subscriptions', null],
    '/guest_account/dogs/': ['Dogs', RouteFormatter.guestAccount()],
    '/guest_account/add-dog': ['Add a dog', RouteFormatter.guestAccount()],
    '/guest_account/profile': ['My profile', RouteFormatter.guestAccount()],
    '/guest_account/new_spot_alerts': ['Spot alerts', RouteFormatter.guestAccount()],
    '/guest_account': ['Account', RouteFormatter.home()],
    '/guest_messages': ['Messages', RouteFormatter.home()],
    '/host_account/spots/': ['Edit spot', null],
    '/host_account/calendar/': ['Calendar', null],
    '/host_account/edit': ['Edit host profile', RouteFormatter.hostAccount()],
    '/host_account/spots': ['My spots', RouteFormatter.hostAccount()],
    '/host_account/spots/new': ['Add a spot', RouteFormatter.hostAccount()],
    '/host_account/reservations/': ['', null],
    '/host_account/reservation': ['Reservations', RouteFormatter.hostAccount()],
    '/host_account/extras': ['Extras', RouteFormatter.hostAccount()],
    '/host_account/earnings/method#completed': ['Payout method', RouteFormatter.earnings()],
    '/host_account/earnings/method': ['Payout method', RouteFormatter.earnings()],
    '/host_account/earnings/history': ['Payouts history', RouteFormatter.earnings()],
    '/host_account/earnings': ['My earnings', RouteFormatter.hostAccount()],
    '/host_account/marketing': ['Marketing tips', null],
    '/host_account/memberships': ['Memberships', RouteFormatter.hostAccount()],
    '/host_account/reviews': ['Reviews by guests', null],
    '/host_account/community': ['Community', RouteFormatter.hostAccount()],
    '/host_account': ['Host dashboard', RouteFormatter.hostAccount()],
    '/host_messages': ['Messages', RouteFormatter.hostAccount()],
    '/host': ['Get started', RouteFormatter.home()],
    '/invite': ['Invite friends', RouteFormatter.home()],
    '/listings': ['', RouteFormatter.home()],
    '/payments': ['Payments', RouteFormatter.guestAccount()],
    '/payments/new': ['Add a payment', RouteFormatter.payments()],
    '/privacy-policy': ['Privacy & Policy', RouteFormatter.home()],
    '/profile/': ['User Profile', null],
    '/reserve/': ['Book your visit', null],
    '/reviews/': ['Reviews', null],
    '/sniffspot-vs-public': ['Sniffspot vs Public', RouteFormatter.home()],
    '/terms-of-service': ['Terms of service', RouteFormatter.home()],
    '/trust': ['Trust & Safety', RouteFormatter.home()],
    '/visit/': ['Visits', null],
    '/visit': ['Visits', RouteFormatter.home()],
    '/waiver': ['Waiver & Release', RouteFormatter.home()],
    '/blog': ['Blog', null],
    '/': ['Sniffspot', null],
};
