import { gql } from '@apollo/client';
import { DOG_SHORT_INFO } from './dog-fragments';
import { REVIEW_SHORT } from './review-fragments';
import { SPOT_INFO } from './spot-fragments';
import { USER_CREDITS_INFO, USER_HOMEPAGE_INFO, USER_LONG_INFO, USER_MEMBERSHIP_SUBS, USER_SPOT_ALERT_FRAGMENT } from './user-fragments';

export const QUERY_USER_DETAILS = gql`
    query myDetails {
        me {
            ...UserLongInfo
            dogs {
                ...DogShortInfo
            }
            ...UserSpotAlert
            spots {
                ...SpotInfo
                spotUrl
                stateShort
                members {
                    id
                }
            }
            ...UserCredits
            blockedUsers {
                id
            }
        }
    }
    ${DOG_SHORT_INFO}
    ${USER_LONG_INFO}
    ${SPOT_INFO}
    ${USER_SPOT_ALERT_FRAGMENT}
    ${USER_CREDITS_INFO}
`;

export const QUERY_USER_ALERTS = gql`
    query myDetails {
        me {
            ...UserSpotAlert
        }
    }
    ${USER_SPOT_ALERT_FRAGMENT}
`;

export const QUERY_USER_CREDITS = gql`
    query myDetails {
        me {
            ...UserCredits
        }
    }
    ${USER_CREDITS_INFO}
`;

export const QUERY_USER_REVIEWS_BY_ID = gql`
    query user($id: ID!, $limitReviews: Int, $limitGuestReviews: Int) {
        user(id: $id) {
            id
            reviews(limit: $limitReviews, offset: 0) {
                ...ReviewShort
                spot {
                    id
                    host {
                        id
                        avatarAws
                    }
                }
            }
            reviewsFromGuests(limit: $limitGuestReviews, offset: 0) {
                ...ReviewShort
                spot {
                    id
                    host {
                        id
                        avatarAws
                    }
                }
            }
        }
    }
    ${REVIEW_SHORT}
`;

export const QUERY_USER_DETAILS_BY_ID = gql`
    query user($id: ID!, $limitReviews: Int, $limitGuestReviews: Int) {
        user(id: $id) {
            id
            firstname
            avatarAws
            activeSniffpass
            nameAndInitial
            createdAt
            city
            state
            about
            aboutHost
            rating
            ratingFromGuests
            reviews(limit: $limitReviews, offset: 0) {
                ...ReviewShort
                spot {
                    id
                    title
                    price
                    latitude
                    longitude
                    city
                    state
                    country
                    zip
                    host {
                        id
                        avatarAws
                    }
                }
            }
            reviewsCount
            reviewsFromGuests(limit: $limitGuestReviews, offset: 0) {
                ...ReviewShort
                spot {
                    id
                    title
                    price
                    latitude
                    longitude
                    city
                    state
                    country
                    zip
                    host {
                        id
                        avatarAws
                    }
                }
            }
            reviewsFromGuestsCount
            mostVisitedSpots {
                id
                title
                city
                state
                thumbPhoto
                visitsCount
                spotUrl
                live
            }
            dogs {
                id
                name
                breeds
                photos {
                    id
                    imageAws
                }
            }
            spots {
                id
                title
                city
                state
                thumbPhoto
                spotUrl
                publishedAt
                live
            }
            memberships {
                id
                active
                spot {
                    id
                    title
                    thumbPhoto
                    spotUrl
                    publishedAt
                    live
                }
            }
        }
    }
    ${REVIEW_SHORT}
`;

export const QUERY_USER_DETAILS_SHORT_BY_ID = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            firstname
            lastInitial
            avatarAws
            createdAt
            city
            state
            about
            rating
            emailVerified
            phoneNumberVerified
            stripeVerified
        }
    }
`;

export const QUERY_USER_NAME_BY_ID = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            nameAndInitial
        }
    }
`;

export const QUERY_USER_SIMPLE_BY_ID = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            firstname
            lastInitial
            avatarAws
            spots {
                id
            }
        }
    }
`;

export const QUERY_USER_PAYOUTS_HISTORY = gql`
    query myDetails($page: Int, $limit: Int) {
        me {
            id
            payouts(page: $page, limit: $limit) {
                collection {
                    id
                    payout
                    success
                    createdAt
                }
                metadata {
                    currentPage
                    limitValue
                    totalCount
                    totalPages
                }
            }
        }
    }
`;

export const QUERY_USER_MEMBERSHIP_SUBS = gql`
    query myDetails {
        me {
            id
            hasCanceledSniffpassTrial
            hasSniffpassTrial
            ...UserMembershipSubs
        }
    }
    ${USER_MEMBERSHIP_SUBS}
`;

export const QUERY_USER_MEMBERSHIP_EARNINGS_BY_SPOT_ID = gql`
    query myMembershipTotalEarnings($spotIds: [ID!]) {
        me {
            id
            membershipTotalEarnings(spotIds: $spotIds)
        }
    }
`;

export const QUERY_USER_HOMEPAGE = gql`
    query myDetails {
        me {
            id
            ...UserHomeInfo
        }
    }
    ${USER_HOMEPAGE_INFO}
`;

export const QUERY_USER_TOTAL_EARNINGS = gql`
    query myEarnings {
        me {
            id
            earnings
        }
    }
`;

export const QUERY_USER_EARNINGS = gql`
    query myEarnings($from: ISO8601Date, $to: ISO8601Date) {
        me {
            id
            memberEarnings: earnings(fromMembers: true, from: $from, to: $to)
            guestEarnings: earnings(fromMembers: false, from: $from, to: $to)
        }
    }
`;

export const QUERY_RESEND_VERIFY_EMAIL = gql`
    query verifyEmail {
        verifyEmail
    }
`;

export const QUERY_RESEND_VERIFY_PHONE = gql`
    query verifyPhoneNumber {
        verifyPhoneNumber
    }
`;

export const QUERY_MY_VERIFY_DETAILS = gql`
    query myDetails {
        me {
            id
            firstname
            emailVerified
            phoneNumberVerified
            stripeVerified
        }
    }
`;

export const QUERY_HOST_ID_VERIFICATION = gql`
    query hostVerification {
        hostVerification {
            status
            url
        }
    }
`;

export const QUERY_HOST_ID_VERIFICATION_STATUS = gql`
    query hostVerification {
        hostVerification {
            status
        }
    }
`;

export const QUERY_USER_INVITED_BY = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            firstname
            avatarAws
        }
    }
`;

export const QUERY_USER_COMMUNITY_POSTS = gql`
    query me($page: Int, $limit: Int) {
        me {
            id
            communities(page: $page, limit: $limit) {
                id
                firstname
                plural
                fromReview
                images
                createdAt
                dogsCount
            }
        }
    }
`;

export const QUERY_USER_GOOG_EMAIL = gql`
    query myGoogEmail {
        me {
            id
            googleCalendarEmail
        }
    }
`;

export const QUERY_USER_MSG_LIST = gql`
    query msgList($page: Int, $limit: Int, $channelType: ChannelFor) {
        me {
            id
            channelsPaged(page: $page, limit: $limit, channelType: $channelType) {
                collection {
                    sid
                    hasUnreadMessage
                    interlocutor {
                        id
                        firstname
                        lastInitial
                        avatarAws
                    }
                    spot {
                        id
                    }
                    lastMessage {
                        body
                        createdAt
                        hasPhoto
                        from {
                            id
                            firstname
                            lastInitial
                        }
                    }
                }
                metadata {
                    totalPages
                    totalCount
                }
            }
        }
    }
`;

export const QUERY_USER_MSG_LIST_SIMPLE = gql`
    query msgList($page: Int, $limit: Int, $channelType: ChannelFor) {
        me {
            id
            channelsPaged(page: $page, limit: $limit, channelType: $channelType) {
                collection {
                    sid
                    hasUnreadMessage
                    interlocutor {
                        id
                    }
                }
            }
        }
    }
`;
