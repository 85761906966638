import { gql } from '@apollo/client';
import {
    SPOT_DETAIL_INFO_SHORT,
    SPOT_FULL,
    SPOT_FULL_LISTING,
    SPOT_LISTINGS_INFO,
    SPOT_LISTINGS_INFO_SEARCH,
    SPOT_MEMBERSHIP_HOST_FRAGMENT,
} from './spot-fragments';
import { F_METADATA } from './common';
import { REVIEW_SHORT } from './review-fragments';

export const SPOT_INFO_QUERY = gql`
    query getSpot($id: ID!) {
        spot(id: $id) {
            ...SpotFull
        }
    }
    ${SPOT_FULL}
`;

export const SPOT_INFO_LISTING_QUERY = gql`
    query getSpot($id: ID!) {
        spot(id: $id) {
            ...SpotFullListing
        }
    }
    ${SPOT_FULL_LISTING}
`;

export const SPOT_SHORT_INFO_QUERY = gql`
    query getSpotShort($id: ID!) {
        spot(id: $id) {
            ...SpotDetailInfoShort
        }
    }
    ${SPOT_DETAIL_INFO_SHORT}
`;

export const SPOT_SHORT_HOST_QUERY = gql`
    query getSpotHostShort($id: ID!) {
        spot(id: $id) {
            id
            title
            rating
            thumbPhoto
            instantBook
            reviewsCount
            city
            state
            timezone
            host {
                id
                firstname
                lastInitial
                avatarAws
                createdAt
                city
                state
                about
                rating
            }
        }
    }
`;

export const SPOT_INFO_CALENDAR_QUERY = gql`
    query getSpot($id: ID!) {
        spot(id: $id) {
            id
            minimumLength
            maximumDogsAllowed
            hasBookedBefore
            isMember
            timezone
        }
    }
`;

export const SPOT_INFO_ID = gql`
    query getSpot($id: ID!) {
        spot(id: $id) {
            id
        }
    }
`;

export const SPOTS_WITH_BOX_QUERY = gql`
    query getSpotsWithBox($bounds: BoundsInputObject, $center: CoordinatesInputObject, $filtersNew: FiltersInputObject) {
        spotsWithBox(bounds: $bounds, center: $center, filtersNew: $filtersNew) {
            spots {
                ...SpotListingsInfo
            }
            box {
                sw {
                    ...latlng
                }
                ne {
                    ...latlng
                }
                center {
                    ...latlng
                }
            }
        }
    }
    fragment latlng on CoordinatesObject {
        lat
        lng
    }
    ${SPOT_LISTINGS_INFO}
`;

export const SPOTS_PAGED_WITH_BOX_QUERY = gql`
    query getSpotsPagedWithBox(
        $bounds: BoundsInputObject
        $center: CoordinatesInputObject
        $filtersNew: FiltersInputObject
        $page: Int
        $limit: Int
        $sortBy: SortBy
        $sortOrder: SortOrder
    ) {
        spotsPagedWithBox(
            bounds: $bounds
            center: $center
            filtersNew: $filtersNew
            page: $page
            limit: $limit
            sortBy: $sortBy
            sortOrder: $sortOrder
        ) {
            spots {
                metadata {
                    ...Metadata
                }
                collection {
                    ...SpotListingsInfo
                }
            }
            box {
                sw {
                    ...latlng
                }
                ne {
                    ...latlng
                }
                center {
                    ...latlng
                }
            }
        }
    }
    fragment latlng on CoordinatesObject {
        lat
        lng
    }
    ${SPOT_LISTINGS_INFO}
    ${F_METADATA}
`;

export const TOP_SPOTS_QUERY = gql`
    query getTopSpots($center: CoordinatesInputObject, $filtersNew: FiltersInputObject, $page: Int, $limit: Int) {
        spotsPaged(center: $center, filtersNew: $filtersNew, page: $page, limit: $limit) {
            collection {
                ...SpotListingsInfo
            }
        }
    }
    ${SPOT_LISTINGS_INFO}
`;

export const LISTING_QUERY = gql`
    query getSpots($bounds: BoundsInputObject, $center: CoordinatesInputObject, $filtersNew: FiltersInputObject) {
        spots(bounds: $bounds, center: $center, filtersNew: $filtersNew) {
            ...SpotListingsInfo
        }
    }
    ${SPOT_LISTINGS_INFO}
`;

export const LISTING_QUERY_SEARCH = gql`
    query spotsWithBox($bounds: BoundsInputObject, $center: CoordinatesInputObject, $filtersNew: FiltersInputObject) {
        spotsWithBox(bounds: $bounds, center: $center, filtersNew: $filtersNew) {
            box {
                center {
                    lat
                    lng
                }
            }
            spots {
                ...SpotListingsInfoSearch
            }
        }
    }
    ${SPOT_LISTINGS_INFO_SEARCH}
`;

export const SPOTS_PAGED_QUERY = gql`
    query getSpotsPaged($bounds: BoundsInputObject, $center: CoordinatesInputObject, $filtersNew: FiltersInputObject, $page: Int, $limit: Int) {
        spotsPaged(bounds: $bounds, center: $center, filtersNew: $filtersNew, page: $page, limit: $limit) {
            metadata {
                ...Metadata
            }
            collection {
                ...SpotListingsInfo
            }
        }
    }
    ${SPOT_LISTINGS_INFO}
    ${F_METADATA}
`;

export const Q_FAVORITE_SPOTS = gql`
    query favorites($filters: FiltersInputObject) {
        favoriteSpots(filters: $filters) {
            id
        }
    }
`;

export const Q_FAVORITE_SPOTS_DETAIlS = gql`
    query favorites($filters: FiltersInputObject) {
        favoriteSpots(filters: $filters) {
            ...SpotListingsInfo
        }
    }
    ${SPOT_LISTINGS_INFO}
`;

export const SPOT_REVIEWS = gql`
    query getSpot($id: ID!, $first: Int!, $sortBy: SortBy, $sortOrder: SortOrder) {
        spot(id: $id) {
            id
            reviews(first: $first, sortBy: $sortBy, sortOrder: $sortOrder) {
                ...ReviewShort
                received
            }
        }
    }
    ${REVIEW_SHORT}
`;

export const SPOT_NEXT_PREV = gql`
    query getSpot($id: ID!, $center: CoordinatesInputObject, $filters: FiltersInputObject) {
        spot(id: $id) {
            id
            nextSpot(center: $center, filters: $filters) {
                id
                spotUrl
            }
            prevSpot(center: $center, filters: $filters) {
                id
                spotUrl
            }
        }
    }
`;

export const HOST_SPOT_REVIEWS = gql`
    query getSpot($id: ID!, $first: Int!, $sortBy: SortBy, $sortOrder: SortOrder) {
        spot(id: $id) {
            id
            reviews(first: $first, sortBy: $sortBy, sortOrder: $sortOrder) {
                ...ReviewShort
                received
                fencingSecureEnough
                cleanAsExpected
                dogsPresent
                peoplePresent
                domesticAnimalsPresent
                dogsEncounters
                dogsEncountersComment
                peopleEncounters
                peopleEncountersComment

                dogsPresentComment
                domesticAnimalsPresentComment
                peoplePresentComment
            }
            cleanlinessPercentage
            dogsPercentage
            fencingPercentage
            otherAnimalsPercentage
            peoplePercentage
        }
    }
    ${REVIEW_SHORT}
`;

export const SUGGESTED_SPOTS = gql`
    query getSuggestedSpots($name: String) {
        spots(name: $name) {
            ...SpotListingsInfo
            thumbPhoto
        }
    }
    ${SPOT_LISTINGS_INFO}
`;

export const SHARE_SPOT_LINK = gql`
    query shareSpotLink($id: ID!, $campaign: String, $content: String, $source: String, $medium: String, $prefix: String, $asHost: Boolean) {
        shareSpotLink(id: $id, campaign: $campaign, content: $content, source: $source, medium: $medium, prefix: $prefix, asHost: $asHost)
    }
`;

export const SPOT_MEMBERSHIP_HOST = gql`
    query spotMembershipHost($id: ID!) {
        spot(id: $id) {
            ...spotMembershipHostFragment
        }
    }
    ${SPOT_MEMBERSHIP_HOST_FRAGMENT}
`;

export const SPOT_REVIEWS_DATA = gql`
    query spotReviewsData($id: ID!, $sortBy: SortBy, $sortOrder: SortOrder) {
        spot(id: $id) {
            id
            reviews(first: 10, sortBy: $sortBy, sortOrder: $sortOrder) {
                ...ReviewShort
            }
        }
    }
    ${REVIEW_SHORT}
`;

export const SPOT_DETAILS_MEMBERSHIP = gql`
    query getSpotDetailsMember($id: ID!) {
        spot(id: $id) {
            id
            title
            thumbPhoto
            isMember
            maximumDogsAllowed
            memberHostBenefits
            memberPrice2Hour
            memberPrice4Hour
            memberPrice8Hour
            offerMembership
            activeMembership
            offerSniffpass
            price
            live
            spotUrl
            country
        }
    }
`;

export const REQUEST_MORE_PHOTOS = gql`
    query requestMorePhotos($id: ID!) {
        requestMorePhotos(id: $id)
    }
`;

export const SPOT_INFO_DISTRACTIONS = gql`
    query spotDistractions($id: ID!, $type: ReviewCommentEnum, $page: Int, $limit: Int) {
        spot(id: $id) {
            id
            reviewComments(type: $type, page: $page, limit: $limit) {
                collection {
                    id
                    comment
                    submittedAt
                    providedBy {
                        nameAndInitial
                    }
                }
                metadata {
                    totalCount
                }
            }
        }
    }
`;
