import { gql } from '@apollo/client';

export const F_METADATA = gql`
    fragment Metadata on Spots {
        totalPages
        totalCount
        filteredCount
        currentPage
        limitValue
    }
`;
