import CookieService from '../services/cookie.service';

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const getRandomTestGroup = (prob) => {
    const result = getRandomInt(1, 101);
    if (result <= parseInt(prob)) {
        return true;
    }
    return false;
};

export const getRandomCookieTestSSR = (cookieName, expires = 90, prob = 50) => {
    let valCookie = CookieService.get(cookieName);
    if (valCookie === null) {
        const newGroup = getRandomTestGroup(prob) ? 'NEW' : 'OLD';
        CookieService.set(cookieName, newGroup, { expires });
        valCookie = newGroup;
    }
    return valCookie === 'NEW';
};

export const getTestWithProbSSR = async ({ cookie, expires = 90, getProbTest, prob, userTestGroup }) => {
    const isTestPresent = CookieService.get(cookie);
    const isTest = isTestPresent == 'NEW';

    if (isTestPresent) {
        return isTest;
    } else {
        try {
            let finalProb = prob;

            if (!finalProb) {
                const { data } = await getProbTest({ variables: { tests: [userTestGroup] } });
                finalProb = data.probabilities?.length > 0 ? data.probabilities[0].probability : 0;
            }

            if (finalProb > 0) {
                const isTestTemp = getRandomCookieTestSSR(cookie, expires, finalProb);
                return isTestTemp;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    return null;
};
