import { gql } from '@apollo/client';

export const QUERY_AB_TEST_PROB = gql`
    query probabilities($tests: [String!]) {
        probabilities(tests: $tests) {
            probability
            test
        }
    }
`;
