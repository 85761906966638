export const INVITE_TYPE = {
    PAID: 'PAID',
    HOST: 'HOST',
};

export const REVIEW_TYPE = {
    SPOT: 'SPOT',
    USER: 'USER',
};

export const USER_STATUS = {
    VERIFIED: 'VERIFIED',
    UNVERIFIED: 'UNVERIFIED',
    UNVERIFIED_NAME: 'UNVERIFIED_NAME',
};

export const isUnverified = (u) => (u?.id ? u?.status !== USER_STATUS.VERIFIED : false);

export const USER_RACE = [
    {
        value: 'American Indian or Alaska Native',
        label: 'American Indian or Alaska Native',
    },
    {
        value: 'Asian',
        label: 'Asian',
    },
    {
        value: 'Black or African American',
        label: 'Black or African American',
    },
    {
        value: 'Hispanic or Latino',
        label: 'Hispanic or Latino',
    },
    {
        value: 'Native Hawaiian or Other Pacific Islander',
        label: 'Native Hawaiian or Other Pacific Islander',
    },
    {
        value: 'White',
        label: 'White',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

export const USER_GENDER = [
    {
        value: 'female',
        label: 'Female',
    },
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'other',
        label: 'Other',
    },
];

export const USER_STRIPE_STATUS = {
    VERIFIED: 'VERIFIED',
    REQUIRES_INPUT: 'REQUIRES_INPUT',
    PROCESSING: 'PROCESSING',
    CANCELED: 'CANCELED',
};

export const HOW_DID_YOU_HEAR = [
    'Reddit',
    'Craigslist',
    'Sniffspot host',
    'Rescue / shelter',
    'Facebook',
    'Tiktok',
    'Instagram',
    'Trainer',
    'Nextdoor',
    'News article',
    'TV news',
    'Google search',
    'Youtube',
    'Friend / word of mouth',
    'Sniffspot blog',
    'Other',
];

const lastHear = HOW_DID_YOU_HEAR[HOW_DID_YOU_HEAR.length - 1];

export const HOW_DID_YOU_HEAR_RANDOM = [].concat(HOW_DID_YOU_HEAR.slice(0, -1).sort(() => 0.5 - Math.random())).concat(lastHear);

export const guestHomeBanners = ['guest|facebook', 'guest|phoneNumber'];
